const Config = class {
    constructor(props) {
        // this.base_server = 'http://127.0.0.1:1128';//森科公司局域网
        //this.base_server = 'https://api.gshmf.com'; //公司服务器
		//this.base_server = 'http://wxzj.gshxkj.com.cn'; //宁县维修资金测试
		//this.base_server = 'https://api-zlgateway.gshmf.com';//正宁县维修资金生产环境
        // this.eureka_server = 'http://39.100.248.92:1111';
       //   this.base_server = 'http://wxzj.gshxkj.com.cn';//森科公司局域网
       //陇南维修资金
        // this.base_server = 'https://api-xhgateway.gsrmf.cn';
        // this.base_server = 'http://127.0.0.1:1128'
         this.base_server = 'https://api-hzgateway.gshmf.com';//正宁县维修资金生产环境
        //this.base_server = 'http://10.98.75.162:1128';

        this.page_server = 'http://127.0.0.1:8066';
        this.tableName = 'easyweb'; // 存储表名
        this.autoRender = false; // 窗口大小改变后是否自动重新渲染表格，解决layui数据表格非响应式的问题，目前实现的还不是很好，暂时关闭该功能
        this.pageTabs = true; // 是否开启多标签
        this.kaiFaShang = { //开发商菜单
            "title": "开发商管理菜单",
            "menus": [{
                    "name": "小区管理",
                    "url": "table"
                },
                {
                    "name": "户室管理",
                    "url": "houseManager"
                },
                {
                    "name": "资金管理",
                    "url": "order"
                },
                {
                    // "name": "交易明细",
                    // "url": "payDetail"
					"name": "资金报表",
					"url": "villagePlan",
					"child": [
						{
							"name": "小区汇总",
							"url": "villagePlan"
						},{
						   "name": "户室明细",
						   "url": "reportPlan"
					},]
                },
                {
                    "name": "入驻审批",
                    //"url": "ownerApply",
                    "url": "villageApply"
                },
                {
                    "name": "企业信息",
                    "url": "qyInfo"
                }
            ]
        };
        this.wuYe = { //物业菜单
            "title": "物业菜单",
            "menus": [{
                    "name": "小区管理",
                    "url": "proVillage",
                },
                {
                    "name": "户室管理",
                    "url": "houseManager"
                },

                {
                    "name": "资金管理",
                    "url": "order"
                },
                {
                    "name": "过户记录",
                    "url": "houseChange"
                },
                {
                    "name": "维修项目管理",
                    "url": "repairItem"
                },
                {
                    // "name": "交易明细",
                    // "url": "payDetail"
                	"name": "资金报表",
                	"url": "villagePlan",
                	"child": [
                		{
                			"name": "小区汇总",
                			"url": "villagePlan"
                		},{
                		   "name": "户室明细",
                		   "url": "reportPlan"
                	},]
                },

                {
                    "name": "企业信息",
                    "url": "qyInfo"
                }
                //                {
                //                    "name": "资金管理",
                //                    "url": "order",
                //                    "child": [, {
                //                        "name": "缴费订单",
                //                        "url": "order"
                //                    },
                //                        // },{
                //                        //     "name":"维修项目汇总",
                //                        //     "url":"repairItem"
                //                        // }
                //                    ]
                //                }, {
                //                    "name": "审批管理",
                //                    "url": "ownerApply"
                //                }, {
                //                    "name": "企业信息",
                //                    "url": "qyInfo"
                //                }
            ]
        };
        this.YZWeiYuan = { //业主委员菜单
            "title": "业主委员菜单",
            "menus": [{
                    "name": "小区管理",
                    "url": "hoaVillage",
                },
                {
                    "name": "户室管理",
                    "url": "houseManager"
                },

                {
                    "name": "资金管理",
                    "url": "order"
                },

                {
                    "name": "维修项目管理",
                    "url": "repairItem"
                },
                {
                    // "name": "交易明细",
                    // "url": "payDetail"
                	"name": "资金报表",
                	"url": "villagePlan",
                	"child": [
                		{
                			"name": "小区汇总",
                			"url": "villagePlan"
                		},{
                		   "name": "户室明细",
                		   "url": "reportPlan"
                	},]
                },

                {
                    "name": "企业信息",
                    "url": "qyInfo"
                }
            ]
        };


        this.YZ = { //业主菜单
            "title": "业主菜单",
            "menus": [{
                    "name": "基础信息",
                    "url": "ownerVillage",
                },
                {
                    "name": "资金管理",
                    "url": "ownerOrder"
                },
                {
                    // "name": "交易明细",
                    // "url": "payDetail"
					"name": "资金汇总",
					"url": "reportPlan"
                }, {
                    "name": "投票管理",
                    "url": "touPiaoList"
                },
                {
                    "name": "个人信息",
                    "url": "ownerInfo"
                },
            ]
        };
    }
}

export default new Config()
