import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index.vue'),
        children: [
            {
                path: '/',
                name: 'main',
                component: () => import('../components/index/index.vue'),
            },
            {
                path: 'touPiaoList',
                name: 'touPiaoList',
                component: () => import('../components/table/YeZhu/ShengPi/touPiaoList.vue')
            },
            {
                path: 'touPiaoDetail',
                name: 'touPiaoDetail',
                component: () => import('../components/table/YeZhu/ShengPi/touPiaoDetail.vue')
            },
            {
                path: 'step3',
                name: 'step3',
                component: () => import('../components/wuYeManagement/step3.vue')
            },
            {
                path: 'checkHouse',
                name: 'checkHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/checkHouse.vue')
            },
            {
                path: 'table',
                name: 'table',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/first_table.vue')
            },

            {
                path: 'wuYeManagement',
                name: 'wuYeManagement',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/wuYeManagement.vue')
            },
            {
                path: 'batchProduct',
                name: 'batchProduct',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/batchProduct/batchProduct.vue')
            },
            {
                path: 'newOrder',
                name: 'newOrder',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/createKFMessage/newOrder.vue')
            },
            {
                path: 'createKFMessage',
                name: 'createKFMessage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/createKFMessage/createKFMessage.vue')
            },
            {
                path: 'reviseKFMessage',
                name: 'reviseKFMessage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/reviseKFMessage/reviseKFMessage.vue')
            },
            {
                path: 'addTower',
                name: 'addTower',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/addTower.vue'),
            },
            {
                path: 'addUnit',
                name: 'addUnit',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/addUnit.vue'),
            },
            {
                path: 'addVillage',
                name: 'addVillage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/addVillage.vue'),
            },
            {
                path: 'chpwd',
                name: 'chpwd',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/chpwd.vue'),
            },
            {
                path: 'qyInfo',
                name: 'qyInfo',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/qyInfo.vue'),
            }, {
                path: 'upVillage',
                name: 'upVillage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/upVillage')
            }, {  //开发商楼幢表格
                path: 'deveTower',
                name: 'deveTower',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/deveTower')
            }, {  //修改楼幢页面
                path: 'upTower',
                name: 'upTower',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/upTower')
            }, {  //开发商单元页面
                path: 'deveUnit',
                name: 'deveUnit',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/deveUnit')
            }, {  //修改单元页面
                path: 'upUnit',
                name: 'upUnit',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/upUnit')
            }, {  //开发商户室页面
                path: 'deveHouse',
                name: 'deveHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/deveHouse')
            }, {  //开发商增加户室页面
                path: 'addHouse',
                name: 'addHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/addHouse')
            }, {  //业主户室审批页面
                path: 'ownerApply',
                name: 'ownerApply',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/ownerApply')
            }, {  //修改户室页面
                path: 'upHouse',
                name: 'upHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/upHouse')
            }, {  //申请户室
                path: 'applyHouse',
                name: 'applyHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/applyHouse')
            }, {  //个人信息维护
                path: 'grInfo',
                name: 'grInfo',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/common/grInfo')
            }, {  //业主信息维护
                path: 'ownerInfo',
                name: 'ownerInfo',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/ownerInfo')
            }, {  //业主修改密码
                path: 'upPwd',
                name: 'upPwd',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/upPwd')
            }, {//物业小区页面
                path: 'proVillage',
                name: 'proVillage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/proVillage')
            }, {//物业信息页面
                path: 'wyInfo',
                name: 'wyInfo',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/wyInfo')
            }, {//物业申请小区入驻
                path: 'appVillage',
                name: 'appVillage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/appVillage')
            }, {//订单页面
                path: 'order',
                name: 'order',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/order')
            }, {//订单详情页面
                path: 'orderDetail',
                name: 'orderDetail',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/wuYeManagement/orderDetail')
            }, {//选择户室
                path: 'checkHouse',
                name: 'checkHouse',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/table/KaiFaShang/checkHouse')
            }, {//业主页面显示小区信息
                path: 'ownerVillage',
                name: 'ownerVillage',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/ownerVillage')
            }, {
                //开发商审批业主提交的入驻小区页面
                path: 'villageApply',
                name: 'villageApply',
                component: () => import('../components/table/KaiFaShang/villageApply')
            }, {
                //物业发起投票
                path: 'vote',
                name: 'vote',
                component: () => import('../components/wuYeManagement/vote')
            }, {
                //维修项目汇总展示
                name: 'repairItem',
                path: 'repairItem',
                component: () => import('../components/wuYeManagement/repairItem')
            }, {
                //投票公示页面
                name: 'votePublic',
                path: 'votePublic',
                component: () => import('../components/wuYeManagement/votePublic')
            }, {
                //业主缴费订单
                name: 'ownerExpendOrder',
                path: 'ownerExpendOrder',
                component: () => import('../components/owner/ownerExpendOrder')
            }, {
                //分摊计划页面
                name: 'sharePlan',
                path: 'sharePlan',
                component: () => import('../components/wuYeManagement/sharePlan')
            }, {
                //交易明细页面
                // name: 'payDetail',
                // path: 'payDetail',
                // component: () => import('../components/common/payDetail')
				//更改为资金汇总
				name: 'reportPlan',
				path: 'reportPlan',
				component: () => import('../components/RePort/reportPlan')
            }, {
				//小区汇总
				name: 'villagePlan',
				path: 'villagePlan',
				component: () => import('../components/RePort/villagePlan')
			},{
				//楼幢汇总
				name: 'reportTower',
				path: 'reportTower',
				component: () => import('../components/RePort/reportTower')
			},{
				//单元汇总
				name: 'reportUnit',
				path: 'reportUnit',
				component: () => import('../components/RePort/reportUnit')
			},{
                //业主委员会的小区管理
                name: 'hoaVillage',
                path: 'hoaVillage',
                component: () => import('../components/yzwyh/hoaVillage')
            }, {
                //物业楼幢
                name: 'proTower',
                path: 'proTower',
                component: () => import('../components/wuYeManagement/proTower')
            }, {
                //物业单元
                name: 'proUnit',
                path: 'proUnit',
                component: () => import('../components/wuYeManagement/proUnit')
            }, {
                //物业户室
                name: 'proHouse',
                path: 'proHouse',
                component: () => import('../components/wuYeManagement/proHouse')
            },{//业主订单页面
                path: 'ownerOrder',
                name: 'ownerOrder',
                meta: {
                    requireAuth: true
                },
                component: () => import('../components/owner/ownerOrder')
            },{//户室管理页面
                path: 'houseManager',
                name: 'houseManager',
                component: () => import('../components/common/houseManage')
            },{
            //户室过户记录页面
               path:'houseChange',
               name:'houseChange',
               component:()=>import('../components/wuYeManagement/houseChange')
            }
        ]
    }, {
        path: '/grInfo',
        name: 'about',
        meta: {
            requireAuth: true
        },
        component: () => import('../components/common/grInfo')
    }, {
        path: '*',
        name: '404',
        component: () => import('../components/404')
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
